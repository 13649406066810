body {
  margin: 0;
  padding: 0;

  font-family: PingFang SC;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 4vw;
  line-height: 4vw;
  display: flex;
  justify-content: center;
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
}
header.show-shadow {
  background: #FDFDFD;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header .headerWrapper {
  position: relative;
  width: 100vw;
  height: 7.3vw;
  padding: 0 15vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .headerWrapper .logo {
  width: 3.8vw;
  height: 2.3vw;
}
header .headerWrapper .headerBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8vw;
}
header .headerWrapper .headerBtn .button {
  position: relative;
  cursor: pointer;
  color: #333333;
  width: 6vw;
  margin: 0 0.8vw;
  text-align: center;
  font-size: 1.3vw;
  line-height: 2vw;
  height: 2vw;
  border-width: 0;
  font-family: Impact;
  background-color: transparent;
  box-shadow: 0 0 0;
  font-weight: 600;
}
header .headerWrapper .headerBtn .button:hover {
  border-bottom: 1px solid #1E7C51;
  color: #1E7C51;
}
header .headerWrapper .headerBtn .active-btn {
  color: #1E7C51;
  border-width: 0;
  font-family: Impact;
  border-bottom: 1px solid #1E7C51;
  box-shadow: 0 0 0;
}
header .headerWrapper .headerBtn .active-btn:hover {
  color: #333;
}
header .btn-white {
  position: relative;
  background: #fedb67;
  padding: 0 18vw 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.layout {
  position: fixed;
  height: 100vh;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(225deg, #26AF81 0%, #1C9DCC 100%) !important;
}
.layout .headerWrapper {
  height: 10vh !important;
}
.layout .banner .bgWrapper {
  height: 100% !important;
  padding-top: 10vh !important;
  margin: 0 !important;
}
.layout .banner .bgWrapper .content .title {
  height: auto!important;
}
.layout .banner .bgWrapper .content .subtitle,
.layout .banner .bgWrapper .content .desc {
  margin-top: 2vh !important;
  font-size: 2vh!important;
  line-height: 2vh!important;
  height: auto!important;
}
.layout .banner .bgWrapper .content .banner-btn {
  width: auto!important;
  height: auto!important;
  padding: 1vh 2.6vh !important;
  margin: 3vh 0!important;
  font-size: 2.9vh !important;
}
.layout .banner .bgWrapper .content .banner-btn .banner-img {
  width: 2.7vh !important;
  height: 2.4vh !important;
}
.layout .banner .bgWrapper .imgWrapper {
  margin-top: 16px;
}
.layout .banner .bgWrapper .imgWrapper img {
  position: fixed;
  bottom: 12vw;
  right: 15vw;
  height: 35vh !important;
}
.layout .footer {
  position: fixed!important;
  z-index: 2!important;
  bottom: 0!important;
  height: 12vw !important;
  color: #262626;
}
.layout .footer .bar {
  height: 10vw;
  padding: 0 !important;
  justify-content: center;
}
.layout .footer .bar .one-center {
  width: 25vw !important;
}
.layout .footer .bar .one-center .infos {
  justify-content: flex-end !important;
}
.layout .footer .bar .one-center-end {
  margin-left: 12vw !important;
}
.layout .footer .bar .one-center-end .infos {
  justify-content: flex-start !important;
}
.layout .footer .bar .title {
  font-size: 1.4vw !important;
  height: 1.4vw !important;
  text-align: left;
  padding-top: 1.5vw !important;
}
.layout .footer .bar .infos {
  text-align: left;
  padding-bottom: 1vw !important;
  margin-bottom: 0 !important;
  font-size: 1vw !important;
}
.layout .footer .bar .infos a {
  text-align: left;
  font-size: 1vw!important;
}
.layout .footer .bottom-bar {
  height: 2vw;
  line-height: 2vw;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.features img {
  margin-top: 0 !important;
}
.block {
  position: relative;
}
.banner {
  position: relative;
  margin: auto;
  width: 100vw;
  overflow: hidden;
  background-size: 100vw auto;
  height: 75vh;
}
.banner .bgWrapper {
  position: relative;
  margin: 0 auto;
  margin-top: 12.5vw;
  margin-left: 19vw;
  margin-right: 16vw;
  text-align: left;
  display: block;
}
.banner .bgWrapper .content {
  position: relative;
  z-index: 1;
  text-align: left;
  margin: 0 15vw;
  padding-top: 5vw;
}
.banner .bgWrapper .content .title {
  font-size: 2.8vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 3.3vw;
  color: #fff;
}
.banner .bgWrapper .content .subtitle {
  margin-top: 4vw;
  font-size: 1.7vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 1.7vw;
  color: #fff;
  height: 3.4vw;
}
.banner .bgWrapper .content .desc {
  margin-top: 1vw;
  font-size: 1.7vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 1.7vw;
  color: #fff;
  height: 3.4vw;
}
.banner .bgWrapper .content .banner-btn {
  width: 20.8vw;
  margin-top: 2.6vw;
  margin-bottom: 2.6vw;
  height: 4.7vw;
  line-height: 3vw;
  background: #fece0a;
  font-size: 1.2vw;
  font-family: Impact;
  font-weight: 400;
  color: #262626;
  opacity: 1;
  border: 0 !important;
  border-radius: 5vw;
}
.banner .bgWrapper .content .banner-btn a {
  color: #262626;
  text-decoration: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner .bgWrapper .content .banner-btn .banner-img {
  margin-right: 0.5vw;
  width: 1.2vw;
  height: 0.9vw;
}
.banner .bgWrapper .imgWrapper {
  margin: 0 auto;
  margin-top: 16px;
  text-align: center;
}
.banner .bgWrapper .imgWrapper img {
  height: 26.2vw;
}
.banner-ims {
  position: absolute;
  left: -3px;
  top: 0;
  width: 100%;
  height: 150px;
  z-index: 3;
}
.banner-andriod {
  width: 17px;
  height: 20px;
  margin: -5px 10px 0 5px;
}
.banner-newIcon {
  width: 47px;
  height: 47px;
  position: absolute;
  top: -16px;
  right: -8px;
  z-index: 999;
}
.banner-word-box {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.banner-word-download {
  width: 275px;
  position: relative;
  height: 110px;
  color: #fff;
  display: flex;
}
.banner-word-download svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-download div {
  position: absolute;
  top: 24px;
  left: 12px;
  z-index: 4;
  color: #fff;
  font-family: Impact;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-word-downloads {
  margin-top: 30px;
  width: 237px;
  position: relative;
  height: 150px;
  color: #fff;
  display: flex;
}
.banner-word-downloads svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-downloads div {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 4;
  color: #fff;
  font-family: Impact;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.banner-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
.block1 {
  overflow: initial;
  width: 100vw;
  height: 46.6vw;
  padding-left: 18.8vw;
  padding-right: 18.8vw;
  position: relative;
  overflow: hidden;
  background-image: url(../../static/media/bg_feature.c94c5115.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 5.2vw;
  padding-top: 8.3vw;
}
.block1 .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.block1 .topTip h2 {
  font-size: 2.2vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 2.2vw;
  color: #FEDB67;
}
.block1 .topTip div {
  line-height: 1.8vw;
  width: 40.9vw;
  font-family: Impact;
  font-size: 1.1vw;
  font-weight: 600;
  color: #FEDB67;
  margin: 1.6vw auto 0;
}
.block1 .block-main-content {
  z-index: 3;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto 0;
}
.block1 .block-main-content .fsTitle {
  padding: 0;
  margin: 0;
  height: 67px;
  font-size: 48px;
  font-family: Impact;
  font-weight: bold;
  line-height: 67px;
  color: #3b3291;
  opacity: 1;
}
.block1 .block-main-content .fsDesc {
  padding: 0;
  margin: 14px 0 88px 0;
  width: 560px;
  font-size: 29px;
  font-family: Impact;
  font-weight: 400;
  line-height: 35px;
  color: #5e57a7;
  opacity: 1;
}
.block1 .one-row-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  max-width: 1200px;
}
.block1 .page-main-feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block1 .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.6vw;
}
.block1 .item-feature .cardInfo {
  width: 14.5vw;
  height: 20vw;
  background-color: #fff;
  border-radius: 1.6vw;
  color: #333333;
  text-align: center;
  padding: 0 1.4vw;
  padding-top: 2.3vw;
}
.block1 .item-feature .cardInfo .cardTitle {
  padding-top: 2.3vw;
  font-size: 1.2vw;
  font-family: Impact;
  font-weight: bold;
  line-height: 1.6vw;
  opacity: 1;
}
.block1 .item-feature .cardInfo .cardDetail {
  margin-top: 0.5vw;
  font-size: 0.8vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 1.15vw;
  opacity: 1;
}
.block1 .item0 .cardIcon {
  height: 5vw;
}
.block1 .item1 .cardIcon {
  height: 5vw;
}
.block1 .item2 .cardIcon {
  height: 5vw;
}
.block1 .item3 .cardIcon {
  height: 5vw;
}
.block1 .one-row-block :nth-child(odd) div img {
  width: 150px;
  height: 150px;
  left: calc(50% - 75px);
}
.block1 .one-row-block :nth-child(even) div img {
  width: 170px;
  height: 130px;
  left: calc(50% - 85px);
}
.block1 .card {
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  color: #868e96;
  display: inline-block;
  max-width: 360px;
  height: 272px;
  padding: 32px;
  box-shadow: 0 2px 2px rgba(84, 48, 132, 0.06);
  margin: 0 auto;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  text-align: center;
  border-radius: 4px;
}
.block1 .card .card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.block1 .card h3 {
  font-size: 30px;
}
.block1 .card img {
  height: 50px;
  margin: 20px 0;
}
.block1 .card:hover {
  text-decoration: none;
  -webkit-transform: translateY(-12px);
          transform: translateY(-12px);
  box-shadow: 0 12px 24px rgba(84, 48, 132, 0.06);
}
.block3 {
  margin-top: 0;
  overflow: initial;
  height: 53vw;
  width: 100vw;
  padding: 0 22vw;
  padding-bottom: 5vw;
  position: relative;
  overflow: hidden;
  background-size: 100%;
  background-repeat: no-repeat;
}
.block3 .topTip {
  width: 100%;
  overflow: hidden;
  text-align: left;
  padding-left: 1vw;
}
.block3 .topTip h2 {
  font-family: Impact;
  font-size: 3vw;
  font-weight: 600;
  color: #1E7C51;
  text-align: center;
  margin-bottom: 0;
}
.block3 .topTip div {
  width: 33vw;
  margin: 0 auto;
  font-size: 1.1vw;
  font-family: Impact;
  line-height: 1.2vw;
  font-weight: 600;
  color: #1E7C51;
  text-align: center;
}
.block3 .page {
  padding: 0 2.3vw;
}
.block3 .one-card {
  background: #ffffff;
  opacity: 1;
  border-radius: 1.8vw;
  display: flex;
  align-items: center;
  margin-top: 1.6vw;
  padding: 1.6vw 1.4vw;
}
.block3 .one-card img {
  width: 8.3vw;
  height: 8.3vw;
}
.block3 .one-card .common-card {
  margin-left: 2vw;
  padding-top: 1vw;
}
.block3 .one-card .common-card .title {
  font-size: 1.1vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 1.1vw;
  margin-bottom: 0;
}
.block3 .one-card .common-card .detail {
  font-size: 1vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 1.1vw;
  color: #333333;
  margin-top: 1vw;
}
.block3 .one-card .common-card .detail p {
  margin-bottom: 0.5vw;
}
.block3 .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block3 .item-feature .cardInfo {
  width: 20vw;
}
.block3 .item-feature .cardInfo .cardTitle {
  font-size: 2vw;
  font-family: Impact;
  font-weight: bold;
  line-height: 3vw;
  opacity: 1;
  color: #2972FB;
}
.block3 .item-feature .cardInfo .cardDetail {
  margin-top: 1vh;
  font-size: 1.5vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 2vw;
  opacity: 1;
}
.block3 .item-feature .card0 {
  text-align: left;
}
.block3 .item-feature .card1 {
  text-align: right;
}
.block3 .item-feature .card1 .cardTitle {
  text-align: right;
}
.block3 .item0 .cardIcon {
  width: 3.3vw;
  margin-right: 7vw;
}
.block3 .item1 {
  margin-top: 7vw;
}
.block3 .item1 .cardIcon {
  width: 20vw;
}
.block3 .item2 {
  margin-top: 7vw;
}
.block3 .item2 .cardIcon {
  margin-right: 7vw;
  width: 15vw;
}
.block3 .item3 {
  margin-top: 14vw;
}
.block3 .item3 .cardIcon {
  width: 18vw;
}
.block4 {
  min-height: 715px;
  padding: 130px 0;
  background: #F8FAFE;
}
.block4 .slick-slide {
  height: 455px;
}
.block4 .slick-slide .user {
  width: 98px;
  height: 98px;
  border-radius: 100%;
  margin: auto;
}
.block4 .slick-slide .comment {
  line-height: 48px;
  font-size: 20px;
  width: 100%;
  max-width: 680px;
  margin: 41px auto 47px;
  position: relative;
  color: #545454;
  font-weight: 300;
}
.block4 .slick-slide .comment > img {
  position: absolute;
  left: -40px;
  top: -15px;
}
.block4 .slick-slide > h4 {
  font-size: 30px;
  line-height: 38px;
  font-weight: normal;
}
.block4 .slick-slide > p {
  margin-top: 16px;
  font-size: 14px;
  color: #888888;
  font-weight: 300;
}
.block4 .slick-dots li {
  margin: 0 8px;
}
.block4 .slick-dots li button {
  transition: opacity 0.3s;
  width: 10px !important;
  height: 10px !important;
  border-radius: 100%;
}
.block4 .slick-dots li button:hover::before,
.block4 .slick-dots li button:focus::before {
  opacity: 1;
  width: 20px;
  height: 20px;
}
.block4 .slick-dots li button::before {
  transition: color 0.3s;
}
.block4 .slick-dots li.slick-active button {
  background: #064852;
}
.block4 .slick-dots li.slick-active button::before {
  opacity: 1;
  background: #064852;
}
.block5 {
  min-height: 590px;
}
.block5 h2 {
  margin: 120px auto 25px;
}
.block5 > span {
  margin-bottom: 72px;
}
.block5 .ant-row > div {
  margin-bottom: 20px;
}
.andriod {
  width: 17px;
  height: 18px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.footer {
  overflow: hidden;
  width: 100vw;
}
.footer .bar {
  background: #FECE0A;
  opacity: 1;
  padding: 2.6vw 24vw;
  color: #262626;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.footer .bar .one-center {
  font-family: PingFang SC;
  font-weight: 600;
  position: relative;
}
.footer .bar .one-center .title {
  padding: 0;
  margin: 0;
  font-size: 1vw;
  line-height: 1vw;
  margin-bottom: 1vw;
}
.footer .bar .one-center .infos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3.2vw;
  font-size: 0.8vw;
}
.footer .bar .one-center .infos .contImg {
  width: 1.1vw;
  height: 0.9vw;
  margin-right: 0.7vw;
  fill: #262626;
}
.footer .bar .one-center-end {
  text-align: right;
}
.footer .bar .one-center-end .infos {
  text-align: right;
  justify-content: flex-end;
}
.footer .bar .one-center-end .infos a {
  color: #262626;
  text-align: right;
  font-family: PingFang SC;
  font-weight: 600;
}
.footer .bar .one-center-end .infos:last-child {
  margin-bottom: 0;
}
.footer .bottom-bar {
  overflow: hidden;
  height: 3.3vw;
  line-height: 3.3vw;
  text-align: center;
  background: #f8f8f8;
  color: #262626;
  font-size: 0.7vw;
  font-family: PingFang SC;
  font-weight: 400;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.layout {
  position: fixed;
  height: 100vh;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(225deg, #26AF81 0%, #1C9DCC 100%) !important;
}
.layout .headerWrapper {
  height: 10vh !important;
}
.layout .banner .bgWrapper {
  height: 100% !important;
  padding-top: 10vh !important;
  margin: 0 !important;
}
.layout .banner .bgWrapper .content .title {
  height: auto!important;
}
.layout .banner .bgWrapper .content .subtitle,
.layout .banner .bgWrapper .content .desc {
  margin-top: 2vh !important;
  font-size: 2vh!important;
  line-height: 2vh!important;
  height: auto!important;
}
.layout .banner .bgWrapper .content .banner-btn {
  width: auto!important;
  height: auto!important;
  padding: 1vh 2.6vh !important;
  margin: 3vh 0!important;
  font-size: 2.9vh !important;
}
.layout .banner .bgWrapper .content .banner-btn .banner-img {
  width: 2.7vh !important;
  height: 2.4vh !important;
}
.layout .banner .bgWrapper .imgWrapper {
  margin-top: 16px;
}
.layout .banner .bgWrapper .imgWrapper img {
  position: fixed;
  bottom: 12vw;
  right: 15vw;
  height: 35vh !important;
}
.layout .footer {
  position: fixed!important;
  z-index: 2!important;
  bottom: 0!important;
  height: 12vw !important;
  color: #262626;
}
.layout .footer .bar {
  height: 10vw;
  padding: 0 !important;
  justify-content: center;
}
.layout .footer .bar .one-center {
  width: 25vw !important;
}
.layout .footer .bar .one-center .infos {
  justify-content: flex-end !important;
}
.layout .footer .bar .one-center-end {
  margin-left: 12vw !important;
}
.layout .footer .bar .one-center-end .infos {
  justify-content: flex-start !important;
}
.layout .footer .bar .title {
  font-size: 1.4vw !important;
  height: 1.4vw !important;
  text-align: left;
  padding-top: 1.5vw !important;
}
.layout .footer .bar .infos {
  text-align: left;
  padding-bottom: 1vw !important;
  margin-bottom: 0 !important;
  font-size: 1vw !important;
}
.layout .footer .bar .infos a {
  text-align: left;
  font-size: 1vw!important;
}
.layout .footer .bottom-bar {
  height: 2vw;
  line-height: 2vw;
}
#reat-content {
  font-family: Impact;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 3.7vw;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.bodyMob {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  background: linear-gradient(225deg, #26AF81 0%, #1C9DCC 100%);
}
.bodyMob .btn-white {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}
.banner-mobile {
  position: relative;
}
.block-mobile {
  position: relative;
}
.header-mobile {
  position: relative;
}
.headerWrapper-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vw;
  line-height: 10vw;
  width: 100%;
}
.headerWrapper-mobile .logo {
  height: 5.2vw;
}
.headerWrapper-mobile .popover-menu {
  width: 300px;
}
.headerWrapper-mobile .popover-menu button.lang {
  margin: 4.2vw auto;
  float: none;
}
.headerWrapper-mobile .popover-menu div.version {
  margin: 8.5vw auto 4.2vw;
  float: none;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner {
  padding: 0;
  overflow: hidden;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner-content {
  padding: 0;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li span {
  color: #2972FB;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li::selection {
  color: #fff;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul .ant-menu-item-selected {
  color: #fff;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul .ant-menu-item::after {
  border-right: 1vw solid #064852 !important;
}
.headerWrapper-mobile .nav-phone-menu {
  fill: #333333;
  width: 5.3vw;
  height: 5.3vw;
  cursor: pointer;
}
.banner-mobile {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 13vw;
  overflow-x: hidden;
}
.banner-mobile .bgWrapper {
  background-size: 100% ;
  position: relative;
  margin: 0 auto;
  text-align: left;
  height: 100%;
}
.banner-mobile .bgWrapper .content {
  position: relative;
  z-index: 1;
  height: 100%;
  padding-top: 1px;
  color: #fff;
}
.banner-mobile .bgWrapper .content .title {
  position: relative;
  font-family: Impact;
  padding-top: 1.2vw;
  width: 81.5vw;
  font-size: 5vw;
  line-height: 6vw;
  font-weight: 400;
  margin-left: 7vw;
}
.banner-mobile .bgWrapper .content .subtitle {
  margin-left: 7vw;
  width: 80vw;
  position: relative;
  padding-top: 4.6vw;
  font-size: 3.3vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 4.5vw;
  margin-bottom: 0.9vw;
}
.banner-mobile .bgWrapper .content .desc {
  width: 80vw;
  margin-left: 7vw;
  font-size: 3.3vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 4.5vw;
  margin-bottom: 4.6vw;
}
.banner-mobile .bgWrapper .content .word-download {
  margin-top: 21px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-mobile .bgWrapper .content .banner-btn {
  width: 70vw;
  position: relative;
  text-align: center;
  margin: 0 auto;
  height: 10vw;
  border: 0;
  background: #fece0a;
  opacity: 1;
  border-radius: 4.2vw;
  font-size: 4vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 4.6vw;
  margin-bottom: 3.5vw;
  color: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-mobile .bgWrapper .content .banner-btn a {
  text-decoration: initial;
  color: #262626;
}
.banner-mobile .bgWrapper .content .banner-btn .banner-img {
  width: 3.7vw;
  height: 3.7vw;
  margin-right: 0.3vw;
}
.banner-mobile .bgWrapper .imgWrapper {
  position: relative;
  z-index: 0;
  margin-top: 14px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}
.banner-mobile .bgWrapper .imgWrapper img {
  height: 180px;
}
.banner-mobile-ims {
  position: absolute;
  left: -0.8vw;
  top: 0;
  width: 100%;
  height: 40vw;
  z-index: 3;
}
.banner-mobile-andriod {
  width: 4.5vw;
  height: 5.3vw;
  margin: -1.3vw 2.6vw 0 1.3vw;
}
.banner-mobile-newIcon {
  width: 12.5vw;
  height: 12.5vw;
  position: absolute;
  top: -4.2vw;
  right: -2.1vw;
  z-index: 999;
}
.banner-mobile-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.banner-mobile-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-mobile-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-mobile-text-wrapper {
  width: 40%;
  padding-right: 13.7vw;
  height: 70.6vw;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-mobile-text-wrapper h1 {
  font-size: 46px;
}
.banner-mobile-text-wrapper p {
  font-size: 4.2vw;
  margin: 13.3vw 0 16vw;
  font-weight: 300;
}
.block1-mobile {
  overflow: initial;
  position: relative;
  overflow: hidden;
}
.block1-mobile .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
  padding-left: 7.1vw;
  padding-right: 7.1vw;
}
.block1-mobile .topTip h2 {
  height: 2.3vw;
  font-size: 4vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 4vw;
  color: #1E7C51;
}
.block1-mobile .topTip img {
  width: 40.5vw;
  height: 4.3vw;
}
.block1-mobile .topTip div {
  padding-top: 1.8vw;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 4vw;
  color: #fff;
}
.block1-mobile .page-main-content {
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 0 4.5vw;
  padding-top: 4.6vw;
  padding-bottom: 9.3vw;
  margin-top: -1px;
}
.block1-mobile .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block1-mobile .item-feature .cardInfo {
  position: relative;
  width: 50vw;
  height: 37vw;
  background: #ffffff;
  border-radius: 2.6vw;
  color: #333333;
}
.block1-mobile .item-feature .cardInfo .cardTitle {
  width: 24vw;
  height: 4.6vw;
  font-size: 3.1vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 4.6vw;
  opacity: 1;
  color: #fff;
}
.block1-mobile .item-feature .cardInfo .cardDetail {
  width: 49.2vw;
  font-size: 1.2vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 2.1vw;
  opacity: 1;
  margin-bottom: 5vw;
}
.block1-mobile .item-feature .card0 {
  text-align: left;
  padding: 3.3vw 0 0 4.6vw;
}
.block1-mobile .item-feature .card0 .cardTitle {
  font-size: 3vw;
}
.block1-mobile .item-feature .card0 .cardDetail {
  width: 36.8vw;
  line-height: 4.3vw;
  font-size: 2vw;
}
.block1-mobile .item-feature .card1 {
  text-align: right;
}
.block1-mobile .item-feature .card1 .cardTitle {
  margin-left: -6vw;
  font-size: 3vw;
  line-height: 4.6vw;
  width: 49.2vw;
  margin-top: 5vw;
}
.block1-mobile .item-feature .card1 .cardDetail {
  width: 39vw;
  font-size: 2vw;
  line-height: 4.3vw;
  margin-left: 4vw;
}
.block1-mobile .item0 {
  margin-top: 4.5vw;
}
.block1-mobile .item0 .cardIcon {
  width: 28.7vw;
  margin-right: 7vw;
  position: absolute;
  left: 6vw;
  bottom: -12vw;
  opacity: 0.3;
}
.block1-mobile .item0 .cardInfo .cardDetail {
  margin-bottom: 3vw;
}
.block1-mobile .item1 {
  margin-top: -7vw;
  margin-left: 43vw;
}
.block1-mobile .item1 .cardIcon {
  width: 29.7vw;
  margin: -24vw 3vw 1vw 0;
  position: absolute;
  left: 12vw;
  top: 10vw;
  opacity: 0.3;
}
.block1-mobile .item2 {
  margin-top: -7vw;
}
.block1-mobile .item2 .cardIcon {
  margin-right: 7vw;
  width: 23.7vw;
  position: absolute;
  left: 6vw;
  bottom: -16vw;
  opacity: 0.3;
}
.block1-mobile .item2 .cardInfo .cardDetail {
  line-height: 4.6vw;
  margin-bottom: 6vw;
  margin-top: 1vw;
}
.block1-mobile .item3 {
  margin-top: -7vw;
  margin-left: 43vw;
}
.block1-mobile .item3 .cardIcon {
  width: 30.3vw;
  margin: -19vw 3vw 3vw 0;
  position: absolute;
  left: 11vw;
  top: 9vw;
  opacity: 0.3;
}
.block1-mobile .item3 .cardInfo .cardTitle {
  margin-top: 9vw;
}
.block3-mobile {
  overflow: initial;
  padding: 0 3.7vw;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  padding-bottom: 4.2vw;
  margin-top: -1px;
}
.block3-mobile .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.block3-mobile .topTip h2 {
  font-size: 4.5vw;
  font-weight: bold;
  line-height: 4.5vw;
  color: #fff;
}
.block3-mobile .topTip div {
  margin-top: 1.8vw;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 4vw;
  color: #1E7C51;
  opacity: 1;
  margin-bottom: 4.6vw;
}
.block3-mobile .one-card {
  background: #ffffff;
  opacity: 1;
  border-radius: 4.2vw;
  display: flex;
  align-items: center;
  margin-top: 2.8vw;
  padding: 2.1vw 3.5vw;
}
.block3-mobile .one-card img {
  width: 20vw;
}
.block3-mobile .one-card .cardImg {
  width: 20vw;
}
.block3-mobile .one-card .common-card {
  margin-left: 3.7vw;
  padding-top: 2vw;
}
.block3-mobile .one-card .common-card .title {
  font-size: 3.3vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 4.6vw;
  margin-bottom: 1.5vw;
}
.block3-mobile .one-card .common-card .detail {
  margin-top: 0.8vw;
  font-size: 3vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 4.3vw;
  color: #333333;
  padding-right: 1vw;
}
.block3-mobile .one-card .common-card .detail p {
  margin-bottom: 0;
}
.footer-mobile {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 4.5vw;
  padding-top: 0;
  margin-top: 0px;
}
.footer-mobile .one-center {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 14px;
  padding: 14px 0;
  border-radius: 21px;
  font-family: PingFang SC;
  font-weight: 400;
  position: relative;
  text-align: center;
}
.footer-mobile .one-center .title {
  font-size: 4.5vw;
  font-weight: bold;
  line-height: 5.3vw;
  color: #fff;
  opacity: 1;
}
.footer-mobile .one-center .infos {
  height: 4.4vw;
  opacity: 1;
  border-radius: 1.8vw;
  width: 48vw;
  margin: 0 auto;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 6.4vw;
  color: #fff;
  margin-top: 2.6vw;
}
.footer-mobile .one-center .infos a {
  color: #fff;
  text-align: left;
  font-family: PingFang SC;
  font-weight: 600;
}
.footer-mobile .one-center-end {
  margin-top: 5.1vw;
  background: #fece0a;
  opacity: 1;
  text-align: left;
  border-radius: 2.8vw 2.8vw 0 0;
  padding: 4.5vw 9vw;
}
.footer-mobile .one-center-end .title {
  font-size: 4vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4vw;
  color: #262626;
}
.footer-mobile .one-center-end .infos {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2.6vw;
}
.footer-mobile .one-center-end .infos div {
  height: 4vw;
  line-height: 4vw;
  font-size: 3.4vw;
  font-family: Impact;
  font-weight: 500;
  color: #262626;
}
.footer-mobile .one-center-end .infos .contImg {
  position: relative;
  width: 3.7vw;
  height: 2.6vw;
  margin-right: 1.8vw;
  margin-top: 1vw;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #064852;
  color: #262626;
}
.ant-menu-item::after {
  border-right: 1vw solid #064852 !important;
}

.contentWrapper {
  background: #fff;
  padding: 20px;
  width: 800px;
  margin: auto;
  border: 1px solid #e5e5e5;
}
.contentWrapper h1 {
  font-size: 24px;
  text-align: center;
  color: #333;
  font-weight: 500;
}
.contentWrapper h2 {
  font-size: 24px;
  text-align: center;
  color: #333;
  font-weight: 500;
}
.contentWrapper p {
  text-align: left;
  text-indent: 30px;
  font-size: 16px;
  line-height: 30px;
  color: #666;
}
@media only screen and (max-width: 830px) {
  .contentWrapper {
    background: #fff;
    padding: 20px;
    width: 100%;
    margin: auto;
    border: 0;
    box-sizing: border-box;
  }
  .contentWrapper h2 {
    font-size: 24px;
    text-align: center;
    color: #262626;
    font-weight: 500;
  }
  .contentWrapper p {
    text-align: left;
    text-indent: 30px;
    font-size: 15px;
    line-height: 30px;
    color: #262626;
  }
}

